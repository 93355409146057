import React from "react";
import Layout from "components/layout";
import SEO from "components/layout/seo";
import { graphql } from "gatsby";
import Mailing from "components/common/mailing";
import Books from "components/pages/content-page/books";
import Tools from "components/pages/content-page/Tools";
import Video from "components/pages/content-page/Video";
import Wishes from "components/pages/content-page/Wishes";
import PodborkaOffer from "components/pages/content-page/PodborkaOffer";
import ogImageGraphDesign from "assets/images/seo/conscious-graphic-design-compilation.jpg";
import ogImageVKGraphDesign from "assets/images/seo/vk/conscious-graphic-design-compilation.jpg";
// import ContentCourseBanner from "components/pages/content-page/course-banner";
import data from "data/pages/conscious-graphic-design-compilation";
// import dataIndex from "data/pages/index";

export const prismicQuery = graphql`
  query ConsciousGraphicDesignCompilationQuery {
    page: prismicPage(uid: { eq: "conscious-graphic-design-compilation" }) {
      uid
      data {
        seodescription {
          text
        }
        seoimage {
          url(imgixParams: { width: 1200 })
          thumbnails {
            vk {
              url(imgixParams: { width: 510 })
            }
          }
        }
      }
    }
  }
`;

const GraphDesignCompilationPage = ({ data: prismicData }) => {
  // const foundationOffer = dataIndex.courseCards.edges.find(
  //   el => el.node.courseTitle === "Фундамент Графдизайна"
  // )?.node;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout>
      <SEO
        title={`${data.contentfulPodborka.contentTitle}`}
        ogImageVk={
          seoData?.seoimage?.thumbnails?.vk?.url || ogImageVKGraphDesign
        }
        ogImage={seoData?.seoimage?.url || ogImageGraphDesign}
        keywords={[
          "подборка",
          "осознанный",
          "гранич",
          "granich",
          "графдизайн",
          "книги",
          "лекции",
          "инструменты",
          "материал",
          "презентации",
        ]}
        description={
          seoData?.seodescription?.text ||
          "Осознанная подборка книг и лекций для старта в графическом дизайне. В ней по крупицам собран самый толковый материал."
        }
        url={`https://granich.design/${data.contentfulPodborka.contentSlug}`}
      />

      <div className="section-top-block" />
      <PodborkaOffer
        // tags={
        //   dataIndex.contentCardsRecommended.nodes.find(
        //     el => el.contentTitle === "Осознанная Подборка по Графдизайну"
        //   ).contentTags
        // }
        dataContentPodborka={data.offerPodborkaGraphDesign}
      />
      <Video
        topDescription="Осознанный Графдизайн — интенсивный. Чтобы успеть на курсе как можно больше, вы можете уже сейчас подготовиться, посмотрев эти видео."
        categoryTwo="Далее, лекции, которые не имеют прямого отношения к курсу. Но если у вас есть время, то для большего охвата материала полезны ↓"
        data={data.videoPodborkaGraphDesign.edges}
      />
      <Mailing />
      <Books
        categoryTwo="Далее книги более общие. Уже не столь практичные. Но толково расширяют кругозор в дизайне ↓"
        data={data.booksPodborkaGraphDesign.edges}
      />
      <Tools data={data.toolsPodborkaGraphDesign.edges} />
      <Wishes />
    </Layout>
  );
};

export default GraphDesignCompilationPage;
